import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URLOpenListenerEvent } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { AuthService } from './auth/auth.service';
import { FcmService } from './services/fcm.service';
import { SignalrService } from './services/signalr.service';
import { TranslationService } from './translation/translation.service';
import { takeUntil, filter } from 'rxjs/operators';
import { App } from '@capacitor/app';
import { Subject } from 'rxjs';
import { Account } from './models/auth/account.model';
import { UserInfo } from './models/account/current-account.model';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(public platform: Platform,
    private fcmService: FcmService,
    private _translationService: TranslationService,
    private _signalrService: SignalrService,
    private _authService: AuthService,
    private router: Router,
    private zone: NgZone,
    private alertController: AlertController
  ) {
    this.initializeApp();

    if (this.platform.is('android') || this.platform.is('ios')) {
      this.fcmService.regusterPush();
    }
  }

  /**
   * Private variables
   */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this._authService.onCurrentUserChangedV2.pipe(filter(acc => acc != null && acc != undefined), takeUntil(this._unsubscribeAll)).subscribe({
      next: (account: UserInfo) => {
        this._translationService.setLanguage(account.language.cultureCode.substring(0, 2).toLocaleLowerCase());
      }
    })

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'https://agrodigit.mk/mobileapp';
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();

        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }
}
