import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, skipLast, switchMap } from 'rxjs/operators';
import { TokenResponse } from 'src/app/models/auth/token-response.model';
import { AuthService, TOKEN_KEY } from '../auth.service';
import { GetResult, Storage } from '@capacitor/storage';
import { AppPlatform } from 'src/app/models/platform.model';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
    
    constructor(private _authService: AuthService, private _router: Router) { }

    private async getToken(): Promise<string> {
        const accessToken: GetResult = await Storage.get({ key: TOKEN_KEY });
        return accessToken.value;
    }

    private urlExceptions: string[] = [
        'Authenticate/refresh-token',
        'login',
        'Authenticate/verify',
        'reset-password'
    ];

    private shouldSkip(url: string): boolean {
        let skip = false;
        this.urlExceptions.forEach((exceptionUrl: string) => {
            if (url.includes(exceptionUrl))
            {
                skip = true;
                return;
            }
        });
        return skip;
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {

        if (request.url.includes('api/v1/')) {
            return from(this.getToken()).pipe(
                switchMap(token => {
                    const cloned = this.addTokenHeader(request, token);
                    return next.handle(cloned).pipe(
                        catchError(errordata => {
                            console.log('ERROR DATA: ', errordata)
                            console.log('ERROR DATA: ', JSON.stringify(errordata))
                            if (errordata.status == 401 && !this.shouldSkip(errordata.url)) {
                                return this.handleUnauthorizedError(request, next);
                            }
                            return throwError(errordata);
                        })
                    );
                })
            );
        } else {
            return next.handle(request);
        }
    }

    private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this._authService.refreshToken()).pipe(
            switchMap((observable: Observable<TokenResponse>) => observable),
            switchMap((newTokens: TokenResponse) => {
                // Save new tokens
                this._authService.storeTokens(newTokens.accessToken, newTokens.refreshToken);
    
                // Retry the original request with the new access token
                return next.handle(this.addTokenHeader(request, newTokens.accessToken));
            }),
            catchError((error) => {
                // If token refresh fails, redirect to the login page
                this._authService.logout().then();
                return throwError(error);
            })
        );
    }

    addTokenHeader(request: HttpRequest<unknown>, accessToken: string) {
        var clonedRequest = request.clone({
            headers: request.headers.set('Platform', AppPlatform.Android.toString()),
        });

        if (request.url.includes('Authenticate/verify') || request.url.includes('login')){
            return clonedRequest;
        }

        return clonedRequest.clone({
            // headers: request.headers.set('Authorization', `Bearer ${accessToken}`).set('Language', this._translateService.currentLang),
            headers: clonedRequest.headers.set('Authorization', `Bearer ${accessToken}`),
        });
    }
}
