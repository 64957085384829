import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AccountOnlineStatus } from '../models/account/account-online-status-changed.model';
import { Account } from '../models/auth/account.model';
import { ChatListItem } from '../models/chat/chat-lazy-load-response.model';
import { ChatMessage } from '../models/chat/chat-message.model';
import { ChatService } from './chat.service';
import { UserInfo } from '../models/account/current-account.model';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private _hubConnection: signalR.HubConnection;

  private _currentUser: UserInfo;
  private _lastGroup: string;

  constructor(private _authService: AuthService,
    private _chatService: ChatService) {
    this._authService.onCurrentUserChangedV2.subscribe({
      next: (account: UserInfo) => {
        this._currentUser = account;
        if (this._currentUser) {
          this.setupSignlR();
        }
      }
    });

    this._authService.onLogout.subscribe({
      next: () => {
        this.leaveGroup(this._lastGroup, true);
        this._lastGroup = null;
      }
    })
  }

  async setupSignlR(): Promise<void> {
    //Creating the SignalR connection
    this._hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Error)
      .withUrl(`${environment.notificationServiceUrl}chat/`, {
        accessTokenFactory: () => this._authService.token
      })
      .withAutomaticReconnect([0, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000])
      .build();

    this._hubConnection.onreconnecting((error: Error) => {
      console.error(`SignalR Connection lost due to error "${error}". Reconnecting...`)
    });

    //Subscribe to the AccountOnlineStatus method of the ChatHub on backend
    this._hubConnection.on('AccountOnlineStatusChange', (onlineStatus: AccountOnlineStatus) => {
      console.log('ONLINE STATUS CHANGED: ', onlineStatus);
      this._chatService.onOnlineStatusChanged.next(onlineStatus);
    });

    //Subscribe to the AccountOnlineStatus method of the ChatHub on backend
    this._hubConnection.on('SendChatDetails', (chat: ChatListItem) => {
      console.log('CHAT DETAILS RECEIVED: ', chat);
      this._chatService.onChatDetailsReceived.next(chat);
    });

    //Subscribe to the AccountOnlineStatus method of the ChatHub on backend
    this._hubConnection.on('SendMessage', (message: ChatMessage) => {
      console.log('CHAT MESSAGE RECEIVED: ', message);
      this._chatService.onChatMessageReceived.next(message);
    });

    await this._hubConnection.start();
    this.joinPrivateGroup(`user`)
  }

  public async joinPrivateGroup(groupName: string) {
    {
      this._hubConnection.invoke<string>('JoinPrivateChatGroup', groupName)
        .then((res) => {
          this._lastGroup = groupName;
          console.log(`Joined private SignalR group ${res}`)
          this.isOnline(14);
        })
        .catch((err) => {
          return console.error(`ERROR WHILE JOINIG GROUP ${groupName} -> `, err.toString());
        });
    }
  }

  public isOnline(userId: number){
    this._hubConnection.invoke<boolean>('IsOnline', userId)
    .then((isUserOnline: boolean) => {
      console.log(`IS USER ONLINE: ${isUserOnline}`)
    })
    .catch((err) => {
      return console.error(`ERROR WHILE CHECKING ONLINE STATUS`, err.toString());
    });
  }

  public async joinGroup(groupName: string) {
    this._hubConnection.invoke('JoinGroup', groupName)
      .then(() => {
        this._lastGroup = groupName;
        console.log(`Joined SignalR group ${groupName}`)
      })
      .catch((err) => {
        return console.error(`ERROR WHILE JOINIG GROUP ${groupName} -> `, err.toString());
      });
  }

  public async leavePrivateGroup(groupName: string, closeConnection: boolean = false) {
    await this._hubConnection.invoke<string>('LeavePrivateChatGroup', groupName).then((privateGroup) => {
      if (closeConnection) {
        this._hubConnection.stop();
      }
      console.log('LEFT PRIVATE SIGNALR GROUP: ', privateGroup);
    }).catch(function (err) {
      return console.error('ERROR WHILE REMOVING FROM GROUP -> ', err);
    });
  }

  public async leaveGroup(groupName: string, closeConnection: boolean = false) {
    await this._hubConnection.invoke('LeaveGroup', groupName).then(() => {
      if (closeConnection) {
        this._hubConnection.stop();
      }
      console.log('LEFT SIGNALR GROUP: ', groupName);
    }).catch(function (err) {
      return console.error('ERROR WHILE REMOVING FROM GROUP -> ', err);
    });
  }

}
