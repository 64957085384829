import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable, Subject, throwError } from 'rxjs';
import { Account } from '../models/auth/account.model';
import { GetResult, Storage } from '@capacitor/storage';
import { LoginModel, LoginModelV2, LoginResponse, LoginResponseV2 } from '../models/auth/login.model';
import { ApiResponse } from '../models/generics/api-response.model';
import { environment } from 'src/environments/environment';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { FcmRequest } from '../models/firebase/fcm-request.model';
import { FcmService } from '../services/fcm.service';
import { AccountCreateRequest } from '../models/auth/signup.model';
import { AccountVerifyRequest } from '../models/auth/account-verify-request.model';
import { PasswordResetRequest } from '../models/auth/password-reset-request.model';
import { ChangePasswordRequest } from '../models/auth/change-password.model';
import { HomeMenuItemV2 } from '../models/app-config/home-menu-item.model';
import { User } from '@codetrix-studio/capacitor-google-auth';
import { TokenResponse } from '../models/auth/token-response.model';
import { UserInfo, UserResponse } from '../models/account/current-account.model';

export const TOKEN_KEY = 'auth-token';
export const REFRESH_TOKEN_KEY = 'refresh-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  onAuthTokenChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  onCurrentUserChangedV2: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
  onLogout: Subject<void> = new Subject<void>();

  homeMenuItems: Array<HomeMenuItemV2>;

  token: string = '';
  isAuthoirzed: boolean = false;

  get _headers(): HttpHeaders {
    return new HttpHeaders({
      Authorization: this.token,
    });
  }

  constructor(private http: HttpClient,
    private _router: Router,
    public platform: Platform) {
    this.loadToken();
  }

  async getUser(): Promise<Account> {
    const account = await Storage.get({ key: 'agro-digit-account' });
    this.onCurrentUserChangedV2.next(JSON.parse(account.value) as UserInfo);
    return JSON.parse(account.value);
  }
  
  async setVerificationStatus(isVerified: boolean) {
    const account = await Storage.get({ key: 'agro-digit-account' });
    if (account) {
      let parsedData = JSON.parse(account.value) as UserInfo;

      if (parsedData) {
        parsedData.isVerified = isVerified;
        await Storage.set({ key: 'agro-digit-account', value: JSON.stringify(parsedData) });
        this.onCurrentUserChangedV2.next(parsedData);
      }
    }

  }

  loadCurrentUserInfo(): void {
    this.getCurrentAccountInfo().pipe(take(1)).subscribe({
      next: async (account: UserInfo) => {
        await Storage.set({ key: 'agro-digit-account', value: JSON.stringify(account) });
        console.log('CURRENT ACCOUNT', account);

        this.onCurrentUserChangedV2.next(account);
        this.onAuthTokenChanged.next(this.token);
        this.isAuthenticated.next(true);
        this.onAuthTokenChanged.next(this.token);
        this.isAuthenticated.next(true);
      },
      error: (ex) => {
        console.log('ERROR WHILE AUTHORIZING: ', ex);

        if (ex.status === 401 && ex.error.message === 'Bad request: Expired Token.') {
          //Refresh token
          this.logout();
        }
      }
    });
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    const accountFromStorage = await Storage.get({ key: 'agro-digit-account' });
    console.log('TOKEN FROM STROAGE: ', token)
    console.log('ACCOUNT FROM STROAGE: ', accountFromStorage)
    if (token && token.value) {

      this.token = token.value;
      if (!this.isAuthoirzed) {
        this.loadCurrentUserInfo();
      }
    } else {
      this.isAuthenticated.next(false);
    }
  }

  async getToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    return token ? token.value : null;
  }

  getCurrentAccountInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${environment.apiV2}user/me`);
  }

  login(credentials: LoginModelV2): Observable<LoginResponseV2> {
    return this.http.post<LoginResponseV2>(`${environment.apiV2}Authenticate/login`, credentials).pipe(
      map((response: LoginResponseV2) => {
        Storage.set({ key: TOKEN_KEY, value: response.accessToken });
        Storage.set({ key: REFRESH_TOKEN_KEY, value: response.refreshToken });
        this.onAuthTokenChanged.next(response.accessToken);
        this.isAuthenticated.next(true);
        this.loadCurrentUserInfo();

        return response;
      })
    );
  }

  storeTokens(accessToken: string, refreshToken: string): void {
    Storage.set({ key: TOKEN_KEY, value: accessToken });
    Storage.set({ key: REFRESH_TOKEN_KEY, value: refreshToken });
    this.onAuthTokenChanged.next(accessToken);
  }

  async logout(): Promise<void> {

    Storage.remove({ key: 'agro-digit-account' });
    Storage.remove({ key: TOKEN_KEY });
    Storage.remove({ key: REFRESH_TOKEN_KEY });
    this.isAuthenticated.next(false);
    this.token = '';
    this.onCurrentUserChangedV2.next(null);
    this.onAuthTokenChanged.next(null);
    this.onLogout.next();

    if (this.platform.is('android') || this.platform.is('ios')) {
      const fcmToken = await Storage.get({ key: 'fcmToken' });

      if (fcmToken && fcmToken.value) {
        //Remove the account associated with the token
        let fcmRequest = new FcmRequest(fcmToken.value, this.platform.is('android') ? 'ANDROID' : 'IOS');
        // this.sendFcmToken(fcmRequest).pipe(take(1)).subscribe(); // TODO: FCM
      }
    }
  }

  signup(model: AccountCreateRequest): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.apiV2}user`, model);
  }

  verify(request: AccountVerifyRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiV2}Authenticate/verify/app`, request)
  }

  resendVerificationCode(email: string): Observable<void> {
    return this.http.put<void>(`${environment.apiV2}Authenticate/verify/app/resend-code`, { email: email })
  }

  sendPasswordResetCode(email: string): Observable<void> {
    return this.http.post<void>(`${environment.apiV2}user/forgot-password`, { email: email });
  }

  resetPassword(request: PasswordResetRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiV2}user/reset-password/app`, request);
  }

  changePassword(request: ChangePasswordRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiV2}user/change-password`, request);
  }

  // TODO: FCM
  // sendFcmToken(request: FcmRequest): Observable<void> {
  //   return this.http.post<void>(`${environment.apiUrl}fcm/subscribe`, request, { headers: this._headers });
  // }

  // removeFcmToken(fcmToken: string): Observable<any> {
  //   return this.http.delete(`${environment.apiUrl}fcm/unsubscribe?fcmToken=${fcmToken}`);
  // }

  googleLogin(googleAuthResponse: User): Observable<LoginResponseV2> {
    console.log('GOOGLE SIGN IN REQUEST: ', JSON.stringify(googleAuthResponse));
    return this.http.post<LoginResponse>(`${environment.apiV2}Authenticate/login/google`, googleAuthResponse).pipe(
      map((response: LoginResponseV2) => {
        Storage.set({ key: TOKEN_KEY, value: response.accessToken });
        Storage.set({ key: REFRESH_TOKEN_KEY, value: response.refreshToken });
        this.onAuthTokenChanged.next(response.accessToken);
        this.isAuthenticated.next(true);
        this.loadCurrentUserInfo();

        return response;
      })
    );
  }

  facebookLogin(accessToken: string): Observable<LoginResponseV2> {
    var request = { accessToken: accessToken };

    return this.http.post<LoginResponse>(`${environment.apiV2}Authenticate/login/facebook`, request).pipe(
      map((response: LoginResponseV2) => {
        Storage.set({ key: TOKEN_KEY, value: response.accessToken });
        Storage.set({ key: REFRESH_TOKEN_KEY, value: response.refreshToken });
        this.onAuthTokenChanged.next(response.accessToken);
        this.isAuthenticated.next(true);
        this.loadCurrentUserInfo();

        return response;
      })
    );
  }

  async refreshToken() {

    let accessToken: GetResult = await Storage.get({ key: TOKEN_KEY });
    let refreshToken: GetResult = await Storage.get({ key: REFRESH_TOKEN_KEY });

    let refreshRequest = new TokenResponse(accessToken.value, refreshToken.value);
    return this.http.post<TokenResponse>(
      `${environment.apiV2}Authenticate/refresh-token`, refreshRequest
    ).pipe(
      catchError(err => {
        console.log("ERROR WHILE REFRESHING A TOKEN WITH REQUEST: ", refreshRequest);
        return throwError(err);
      })
    );
  }
}
