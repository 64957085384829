import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { FcmRequest } from '../models/firebase/fcm-request.model';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  onPushNotiicationReceived: Subject<PushNotificationSchema> = new Subject<PushNotificationSchema>();

  private _token: string;

  private get _headers(): HttpHeaders {
    return new HttpHeaders({
      Authorization: this._token,
    });
  }

  constructor(
    public platform: Platform,
    private _http: HttpClient,
    private _authService: AuthService
  ) {
    this._authService.onAuthTokenChanged
      .pipe(filter((t) => t != null))
      .subscribe((token: string) => {

        this._token = token;

        // if(this.platform.is('android') || this.platform.is('ios')){
        //   this.regusterPush();
        // } ///// REGISTER ON STARTUP
      });
  }

  regusterPush() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
        console.error('PUSH NOTIFICATIONS NOT REGISTERED!');
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      // alert('Push registration success, token: ' + token.value);
      if (token.value) {
        // TODO: FCM
        // this._authService.sendFcmToken(new FcmRequest(token.value, 'android')).pipe(take(1)).subscribe(() => {
        //   Storage.set({ key: 'fcmToken', value: token.value });
        // }, (err) => {

        //   console.log('FCM TOKEN SEND FAIL: ', JSON.stringify(err));
        // });
      }
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      // alert('Error on registration: ' + JSON.stringify(error));
      console.log('FCM TOKEN REGISTRATION ERROR: ', error);
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        alert('Push received: ' + JSON.stringify(notification));
        this.onPushNotiicationReceived.next(notification);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        alert('NOTIFICATION ACTION PERFORMED: ' + JSON.stringify(notification));
      }
    );
  }
}
