import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AccountOnlineStatus } from '../models/account/account-online-status-changed.model';
import { ChatLazyLoadFilter } from '../models/chat/chat-lazy-load-filter.model';
import { ChatListItem } from '../models/chat/chat-lazy-load-response.model';
import { ChatMessageCreate } from '../models/chat/chat-message-create.model';
import { ChatMessageLazyLoadFilter } from '../models/chat/chat-message-lazy-load-filter.model';
import { AgronomistAdviceChatResponse, ChatMessage, ChatMessageCreateRequest } from '../models/chat/chat-message.model';
import { ApiResponse } from '../models/generics/api-response.model';
import { PagedResponse, PagedResponseV2 } from '../models/lazy-loading/paged-response.model';
import { Chat, ChatFullSearchRequest } from '../models/chat/chat-full-search.model';
import { ChatMessageFullSearchRequest } from '../models/chat/chat-message-full-search.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  onOnlineStatusChanged: Subject<AccountOnlineStatus> = new Subject<AccountOnlineStatus>();
  onChatDetailsReceived: Subject<ChatListItem> = new Subject<ChatListItem>();
  onChatMessageReceived: Subject<ChatMessage> = new Subject<ChatMessage>();

  private _headers: HttpHeaders;

  constructor(private _authService: AuthService,
    private _http: HttpClient) {
    this._authService.onAuthTokenChanged.subscribe({
      next: (token: string) => {
        if (token) {
          this._headers = new HttpHeaders({
            Authorization: token,
          })
        } else {
          this._headers = null;
        }
      }
    });
  }

  search(request: ChatFullSearchRequest, type: string): Observable<PagedResponseV2<Chat>>{

    if (type == "agronomist"){
      return this._http.post<PagedResponseV2<Chat>>(`${environment.apiV2}Chat/search/agronomist-advice`, request);
    } else {
      // TODO: Replace with AD
      return this._http.post<PagedResponseV2<Chat>>(`${environment.apiV2}Chat/search/agronomist-advice`, request);
    }

  }

  searchMessages(request: ChatMessageFullSearchRequest, chatId: number): Observable<PagedResponseV2<ChatMessage>> {
    return this._http.post<PagedResponseV2<ChatMessage>>(`${environment.apiV2}Chat/${chatId}/message/search`, request);
  }

  addMessage(chatId: number, request: ChatMessageCreateRequest): Observable<ChatMessage>{  
    const formData = new FormData();
    if (request.text) {
      formData.append('text', request.text);
    }
    if (request.picture) {
      formData.append('picture', request.picture, request.picture.name);
    }
    if (request.video) {
      formData.append('video', request.video, request.video.name);
    }
    return this._http.post<ChatMessage>(`${environment.apiV2}Chat/${chatId}/message`, formData)
  }

  getChatList(filter: ChatLazyLoadFilter, type: string): Observable<PagedResponse<ChatListItem>> {
    return this._http.post<ApiResponse<PagedResponse<ChatListItem>>>(`${environment.apiUrl}chat/list/${type}`, filter, { headers: this._headers }).pipe(map(function (data: ApiResponse<PagedResponse<ChatListItem>>) {
      return data.response;
    }));
  }
  getChat(chatId: string): Observable<ChatListItem> {
    return this._http.get<ApiResponse<ChatListItem>>(`${environment.apiUrl}chat?chatId=${encodeURIComponent(chatId)}`, { headers: this._headers }).pipe(map(function (data: ApiResponse<ChatListItem>) {
      return data.response;
    }));
  }

  getChatMessages(filter: ChatMessageLazyLoadFilter): Observable<PagedResponse<ChatMessage>> {
    return this._http.post<ApiResponse<PagedResponse<ChatMessage>>>(`${environment.apiUrl}chat/message/list`, filter, { headers: this._headers }).pipe(map(function (data: ApiResponse<PagedResponse<ChatMessage>>) {
      return data.response;
    }));
  }

  sendChatMessage(message: ChatMessageCreate): Observable<void> {
    return this._http.post<void>(`${environment.apiUrl}chat/message`, message, { headers: this._headers });
  }

  acceptProfileViewRequest(agronomistAdviceId: string): Observable<void> {
    return this._http.put<void>(`${environment.apiUrl}agronomist/advice/profile/view/accept?agronomistAdviceId=${encodeURIComponent(agronomistAdviceId)}`, null,  { headers: this._headers });
  }

  declineProfileViewRequest(agronomistAdviceId: string): Observable<void> {
    return this._http.put<void>(`${environment.apiUrl}agronomist/advice/profile/view/reject?agronomistAdviceId=${encodeURIComponent(agronomistAdviceId)}`, null, { headers: this._headers });
  }
}
