// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:5171/',
  // apiV2:"http://localhost:5000/api/v1/",
   apiV2: "https://api2.agrodigit.techup.me/api/v1/",
  apiUrl: 'https://api.agrodigit.techup.me/',
  googleWebSignInClientId: '561690576248-21f3ch9p8p1b3fnrdeohvnlnkh9a3nai.apps.googleusercontent.com',
  googleAndroidSignInClientId: '561690576248-21f3ch9p8p1b3fnrdeohvnlnkh9a3nai.apps.googleusercontent.com',
  facebookAppId: '1294726754738866',
  notificationServiceUrl: 'http://localhost:5038/',
  stripeKey: "pk_test_51LxbBvCvC4EmB1VPRXK8zqzlhOgyBPG5eAbqlPfMyz13J7YSWepi516IiuLe8VJN57Fq09fjhpjK3KCwW0cxI7TG00T8f4xKg7"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
