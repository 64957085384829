
import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from '@capacitor/storage';
import { AuthService } from "../auth/auth.service";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { UserInfo } from "../models/account/current-account.model";

export interface Locale {
  lang: string;
  data: any;
}

const LOCALIZATION_LOCAL_STORAGE_KEY = "language";

@Injectable({
  providedIn: "root",
})
export class TranslationService implements OnDestroy {

  /**
   * Private variables
   */
  private langIds: any = [];
  
  private _unsubscribeAll: Subject<void> = new Subject<void>();
 

  constructor(
    private translate: TranslateService,
    private _authService: AuthService) {
    // add new langIds to the list
    this.translate.addLangs(["mk"]);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("mk");

    this._authService.onCurrentUserChangedV2
        .pipe(takeUntil(this._unsubscribeAll), filter(user => user != undefined))
        .subscribe({
          next: (user: UserInfo) =>{
            let cultureCode = user.language.cultureCode.substring(0,2);
            if (this.translate.currentLang?.toLowerCase() != cultureCode) {
              this.setLanguage(cultureCode);
            }
          }
        })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Function to set language
   */
 setLanguage(lang: any) {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      console.log('LANGUAGE UPDATED TO: ', lang);
      // localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
      Storage.set({key: LOCALIZATION_LOCAL_STORAGE_KEY, value: lang}).then(() => {});
    }
  }

  /**
   * Function to return selected language
   */
  async getSelectedLanguage() {
    return (
      await Storage.get({ key: LOCALIZATION_LOCAL_STORAGE_KEY }) ||
      this.translate.getDefaultLang()
    );
  }
}